import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "../node_modules/react-toastify/dist/ReactToastify.css";
import NavBar from "./shared/NavBar/NavBar";
import HomePage from "./components/HomeComponents/HomePage";
import AboutUs from "./components/AboutUsPage/AboutUs";
import CosmeticsProducts from "./components/CosmeticProducts/CosmeticsProducts";
import NutretionProducts from "./components/NutretionProducts/NutretionProducts";
import SupplementProducts from "./components/SupplementProducts/SupplementProducts";
import PharmaceuticalsProducts from "./components/PharmaceuticalsProducts/PharmaceuticalsProducts";
import ContactUs from "./components/ContactUs/ContactUs";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import Footer from "./shared/Footer/Footer";
import NotFound from "./components/AboutUsPage/NotFound/NotFound";
import "./App.css";
import "./media.css";
import React, { Suspense } from "react";
function App() {
  const AboutUs = React.lazy(() => import("./components/AboutUsPage/AboutUs"));
  const ContactUs = React.lazy(() => import("./components/ContactUs/ContactUs"));
  const SupplementProducts = React.lazy(() => import("./components/SupplementProducts/SupplementProducts"));
  const CosmeticsProducts = React.lazy(() => import("./components/CosmeticProducts/CosmeticsProducts"));
  const NutretionProducts = React.lazy(() => import("./components/NutretionProducts/NutretionProducts"));

  return (
    <div>
      <BrowserRouter>
        <NavBar />
        <ToastContainer />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<HomePage />} />

          <Route
            path="/about"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <AboutUs />
              </Suspense>
            }
          />
          <Route
            path="/contactus"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ContactUs />
              </Suspense>
            }
          />
          <Route path="/SupplementProducts" element={ <Suspense fallback={<div>Loading...</div>}>
                <SupplementProducts />
              </Suspense>} />
          <Route path="/CosmeticsProducts" element={<Suspense fallback={<div>Loading...</div>}>
                <CosmeticsProducts />
              </Suspense>} />
          <Route path="/NutretionProducts" element={<Suspense fallback={<div>Loading...</div>}>
                <NutretionProducts />
              </Suspense>} />
          <Route path="/:category/:name/:id" element={<ProductDetails />} />
          <Route path="/nutretionproducts" element={<NutretionProducts />} />
          <Route path="/PharmaceuticalsProducts" element={<PharmaceuticalsProducts />} />
          
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
export default App;
