const PageCardCategory = ({ i }) => {
  return (
    <>
      <div className="col-12 col-md-4 col-sm-6 col-lg-3 p-3 d-flex">
        <div className="CardWrapper  px-0 pb-1">
          <img className="w-100" src={i?.image} alt="" />
          <div className="px-1">
            <p
              className="pt-1 mb-0  text-capitalize"
              style={{ color: "#239e37", cursor: "pointer", fontSize: "2.3rem" }}
            >
              {i?.name} <br />
            </p>
            <p className=" text-capitalize second__card__p" style={{ cursor: "pointer", fontWeight: "100" }}>
              {i?.slogan}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default PageCardCategory;
