const IMAGES = {
  supplement: {
    mainImage: require("./supplement/card-01.jpg"),
    subImages: [
      require("./supplement/slider2.jpeg"),
      require("./supplement/slider2.jpeg"),
      require("./supplement/slider2.jpeg"),
      require("./supplement/slider2.jpeg"),
      require("./supplement/slider2.jpeg"),
      require("./supplement/slider2.jpeg"),
      require("./supplement/slider2.jpeg"),
      require("./supplement/slider2.jpeg"),
      require("./supplement/slider2.jpeg"),
      require("./supplement/slider2.jpeg"),
    ],
  },
};

export default IMAGES;
